import React from 'react'
import { media, Capital, H1, H2, BodyLarge, BodySmall, Block } from '@thesisedu/web/dist'
import {
  PhoneFilled,
  MailOutlined,
  MessageOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { lighten, transparentize } from 'polished'
import { HoverBrochureCardSmall } from '../styled'

export interface SupportWidgetProps {
  hideTitle?: boolean
}
export function SupportWidget({ hideTitle }: SupportWidgetProps) {
  return (
    <>
      {!hideTitle ? (
        <Block style={{ textAlign: 'center' }}>
          <Capital isBlock color={'@primary-color'}>
            Support
          </Capital>
          <H1>24/7 world-class support. Always included.</H1>
        </Block>
      ) : null}
      <ItemsContainer id={'support'}>
        <SupportItem
          title={'Call us.'}
          subtitle={'Phone'}
          subtext={'302.307.1997'}
          href={'tel:3023071997'}
          icon={<PhoneFilled />}
        >
          We're here for you, any time of day.
        </SupportItem>
        <SupportItem
          title={'Email us.'}
          subtitle={'Email'}
          subtext={'support@prodigiesacademy.com'}
          href={'mailto:support@prodigiesacademy.com'}
          icon={<MailOutlined />}
        >
          We know you're busy. Drop us a line and we'll help out.
        </SupportItem>
        <SupportItem
          title={'Chat us.'}
          subtitle={'Online Chat'}
          subtext={'Click to chat now.'}
          onClick={() => {
            if ((window as any).Intercom) {
              ;(window as any).Intercom('showNewMessage')
            }
          }}
          icon={<MessageOutlined />}
        >
          Instant support, at your fingertips.
        </SupportItem>
        <SupportItem
          title={'Self serve.'}
          subtitle={'Knowledge Base'}
          subtext={'Click to view it.'}
          href={'/learn/articles'}
          isLink
          icon={<QuestionCircleOutlined />}
        >
          Our knowledge-base contains a wealth of helpful articles &amp; guides.
        </SupportItem>
      </ItemsContainer>
    </>
  )
}

const ItemsContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 calc(-${(props) => props.theme['@size-l']} / 2);
  > * {
    margin: 0 calc(${(props) => props.theme['@size-l']} / 2) ${(props) => props.theme['@size-l']}
      calc(${(props) => props.theme['@size-l']} / 2);
    flex-basis: 100%;
    ${media.md} {
      flex-basis: 25%;
    }
  }
  ${media.xl} {
    justify-content: stretch;
    flex-wrap: nowrap;
  }
`

export interface SupportItemProps {
  title: string
  subtitle: string
  subtext: string | React.ReactElement
  href?: string
  isLink?: boolean
  onClick?: () => void
  icon: React.ReactElement
}
export function SupportItem({
  title,
  subtitle,
  subtext,
  href,
  isLink,
  onClick,
  icon,
  children
}: React.PropsWithChildren<SupportItemProps>) {
  const content = (
    <ItemContainer onClick={onClick}>
      <Capital className={'capital'} color={'@gray-0'} isBlock>
        {subtitle}
      </Capital>
      <H2 isBlock color={'@gray-0'} style={{ marginTop: 0 }}>
        {title}
      </H2>
      <BodyLarge style={{ opacity: 0.75 }} color={'@gray-0'}>
        {children}
      </BodyLarge>
      <Block marginTop={'@size-xs'}>
        <BodySmall color={'@gray-0'}>{subtext}</BodySmall>
      </Block>
      <div className={'icon-container'}>{icon}</div>
    </ItemContainer>
  )

  return href ? (
    isLink ? (
      <Link to={href}>{content}</Link>
    ) : (
      <a href={href}>{content}</a>
    )
  ) : (
    <div>{content}</div>
  )
}

const ItemContainer = styled(HoverBrochureCardSmall)`
  background-image: linear-gradient(
    226deg,
    ${(props) => lighten(0.07, props.theme['@primary-color'])} 5%,
    ${(props) => props.theme['@primary-color']} 100%
  );
  box-shadow: 0 5px 50px 0 ${(props) => transparentize(0.75, props.theme['@primary-color'])};
  padding: ${(props) => props.theme['@size-m']} ${(props) => props.theme['@size-s']}
    ${(props) => props.theme['@size-s']} ${(props) => props.theme['@size-s']};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: ${(props) => props.theme['@border-radius-large']};
  height: 100%;
  position: relative;
  ${media.xl} {
    padding-top: ${(props) => props.theme['@size-xl']};
    height: 420px;
  }
  &:hover {
    box-shadow: 0 10px 50px 0 ${(props) => transparentize(0.6, props.theme['@primary-color'])};
  }
  &:active {
    box-shadow: 0 1px 50px 0 ${(props) => transparentize(0.6, props.theme['@primary-color'])};
  }
  .capital {
    color: ${(props) => props.theme['@gray-0']};
    opacity: 0.5;
  }
  .icon-container {
    position: absolute;
    top: ${(props) => props.theme['@size-s']};
    right: ${(props) => props.theme['@size-s']};
    margin-top: auto;
    align-self: flex-end;
    color: ${(props) => props.theme['@gray-0']};
    opacity: 0.4;
    font-size: ${(props) => props.theme['@size-xl']};
    span {
      display: block;
    }
    ${media.md} {
      position: relative;
      top: auto;
      right: auto;
      padding-top: ${(props) => props.theme['@size-m']};
    }
  }
`
