import React from 'react'
import styled from 'styled-components'
import { media } from '@thesisedu/web/dist'

export interface PageHeroProps {
  rightContent: React.ReactElement
}
export function PageHero({ children, rightContent }: React.PropsWithChildren<PageHeroProps>) {
  return (
    <Container>
      <div>{children}</div>
      <div>{rightContent}</div>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${(props) => props.theme['@size-section-md']};
  overflow: visible;
  max-width: 100vw;
  > div:first-child {
    width: 520px;
    max-width: 95vw;
    flex-shrink: 0;
    padding-right: ${(props) => props.theme['@size-xl']};
  }
  > div:last-child {
    flex: 1;
    margin-left: ${(props) => props.theme['@size-xl']};
    overflow: visible;
  }
`
