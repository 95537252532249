import React from 'react'
import styled from 'styled-components'
import { Transition, media, Capital } from '@thesisedu/web/dist'
import { Tabs } from 'antd'
import { useStaticQuery, graphql } from 'gatsby'
import Case from 'case'
import { lighten } from 'polished'
import { Container } from '../styled'
import { PlatformQuery } from '../../graphqlTypes'
import { PlatformItem } from './PlatformItem'
import { useHashEffect } from '../useHashEffect'

const query = graphql`
  query platform {
    content: allFile(
      sort: { order: [ASC], fields: [name] }
      filter: { sourceInstanceName: { eq: "platform" } }
    ) {
      edges {
        node {
          ...PlatformPiece
        }
      }
    }
  }
`

export function PlatformWidget() {
  const data = useStaticQuery<PlatformQuery>(query)
  const platforms = data.content.edges.map((edge) => edge.node)
  const [selectedPlatformKey, setSelectedPlatformKey] = React.useState<string>(
    platforms[0]?.id || ''
  )
  const containerRef = React.useRef<HTMLDivElement>(null)
  useHashEffect(() => {
    const platform = platforms.find(
      (p) =>
        Case.kebab(p.childMarkdownRemark.frontmatter.title) ===
        window.location.hash.replace('#', '')
    )
    if (platform) {
      setSelectedPlatformKey(platform.id)
      if (containerRef.current) {
        containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }
  })
  if (platforms.length) {
    return (
      <OuterContainer id={'platform'} ref={containerRef}>
        <Container>
          <Capital color={'@orange'}>Platform</Capital>
          <Tabs animated activeKey={selectedPlatformKey} onChange={setSelectedPlatformKey}>
            {platforms.map((platform) => (
              <Tabs.TabPane tab={platform.childMarkdownRemark.frontmatter.title} key={platform.id}>
                <span />
              </Tabs.TabPane>
            ))}
          </Tabs>
          <Transition state={selectedPlatformKey} type={'fade-scale'}>
            <PlatformItem platform={platforms.find((p) => p.id === selectedPlatformKey)!} />
          </Transition>
        </Container>
      </OuterContainer>
    )
  }
  return null
}

const OuterContainer = styled.div`
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23f7f7f9' fill-opacity='0.15'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E"),
    linear-gradient(
      226deg,
      ${(props) => lighten(0.07, props.theme['@primary-color'])} 5%,
      ${(props) => props.theme['@primary-color']} 100%
    );
  background-blend-mode: soft-light;
  padding: ${(props) => props.theme['@size-xl']} 0;
  margin: ${(props) => props.theme['@size-section-md']} 0;
  .ant-tabs-nav-operations svg {
    fill: ${(props) => props.theme['@gray-0']} !important;
    margin-top: ${(props) => props.theme['@size-s']};
  }
  .ant-tabs-nav-list {
    ${media.lg} {
      width: 100%;
      justify-content: space-between;
    }
    .ant-tabs-ink-bar {
      background: ${(props) => props.theme['@orange']};
      height: 3px;
      width: 50px !important;
    }
    .ant-tabs-tab {
      padding-bottom: ${(props) => props.theme['@size-xs']};
      .ant-tabs-tab-btn {
        transition: opacity 0.25s linear;
        opacity: 0.5;
        font-size: ${(props) => props.theme['@size-l']};
        font-weight: 800;
        letter-spacing: -0.75px;
        color: ${(props) => props.theme['@gray-0']} !important;
      }
      &:hover .ant-tabs-tab-btn,
      &.ant-tabs-tab-active .ant-tabs-tab-btn {
        opacity: 1;
      }
    }
  }
`
