import React from 'react'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

export function SBCISD() {
  const result = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "client/sbcisd.png" }) {
        childImageSharp {
          fixed(height: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Img
      fixed={result.file.childImageSharp.fixed}
      alt={'San Benito Consolidated Independent School District, TX'}
    />
  )
}
