import React from 'react'
import styled from 'styled-components'
import { Capital, Block } from '@thesisedu/web/dist'
import { Divider } from 'antd'
import { navigate } from 'gatsby'
import { GlowButton, PatternBox } from '../styled'
import { NHPS } from './NHPS'
import { Tustin } from './Tustin'
import { SBCISD } from './SBCISD'
import { NorthWhite } from './NorthWhite'
import { CreativeLearningFL } from './CreativeLearningFL'
import { WoodCounty } from './WoodCounty'

export function ClientSummary() {
  return (
    <Block marginTop={'@size-section-sm'}>
      <Divider>
        <Capital>Join our 2,000+ Music Educators</Capital>
      </Divider>
      <TestimonialContent>
        <NHPS />
        <Tustin />
        <WoodCounty />
        <SBCISD />
        <NorthWhite />
        <CreativeLearningFL />
      </TestimonialContent>
      <Divider>
        <GlowButton type={'primary'} onClick={() => navigate('/testimonials')}>
          Testimonials
        </GlowButton>
      </Divider>
    </Block>
  )
}

const TestimonialContent = styled(PatternBox)`
  padding: 0 ${(props) => props.theme['@size-m']};
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
  > * {
    margin: ${(props) => props.theme['@size-m']};
  }
`
