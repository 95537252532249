import React from 'react'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

export function Tustin() {
  const result = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "client/tustin@2x.png" }) {
        childImageSharp {
          fixed(height: 70) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Img fixed={result.file.childImageSharp.fixed} alt={'Tustin Unified School District, CA'} />
  )
}
