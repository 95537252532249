import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Body, H3Alternate, media } from '@thesisedu/web/dist'
import Img from 'gatsby-image'
import { transparentize } from 'polished'
import { PlatformPieceFragment } from '../../graphqlTypes'
import { BrochureCardSmall } from '../styled'

export const fragment = graphql`
  fragment PlatformPiece on File {
    id
    childMarkdownRemark {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        subtitle
      }
    }
  }
`

export interface PlatformItemProps {
  platform: PlatformPieceFragment
}
export function PlatformItem({ platform }: PlatformItemProps) {
  const { frontmatter } = platform.childMarkdownRemark
  return (
    <Container>
      <BrochureCardSmall className={'image-card'}>
        <Img fluid={frontmatter.image.childImageSharp.fluid} alt={frontmatter.title} />
      </BrochureCardSmall>
      <div>
        <H3Alternate isBlock color={'@gray-0'}>
          {frontmatter.subtitle}
        </H3Alternate>
        <Body
          color={'@gray-0'}
          useDiv
          dangerouslySetInnerHTML={{ __html: platform.childMarkdownRemark.html }}
        />
      </div>
    </Container>
  )
}

const Container = styled.div`
  flex-direction: column;
  display: flex;
  .image-card {
    padding: ${(props) => props.theme['@size-s']};
    margin-bottom: ${(props) => props.theme['@size-m']};
    img {
      border-radius: calc(${(props) => props.theme['@border-radius-large']} - 4px);
    }
  }
  ${media.lg} {
    flex-direction: row;
    align-items: center;
    padding: ${(props) => props.theme['@size-xl']} 0;
    .image-card {
      margin: 0 ${(props) => props.theme['@size-xl']} 0 0;
      min-width: 60%;
    }
  }
  p {
    color: ${(props) => transparentize(0.2, props.theme['@gray-0'])};
    a {
      opacity: 1;
      color: ${(props) => props.theme['@gray-0']};
    }
  }
`
