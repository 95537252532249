import React from 'react'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

export function CreativeLearningFL() {
  const result = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "client/creative-learning-fl@2x.png" }) {
        childImageSharp {
          fixed(height: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return <Img fixed={result.file.childImageSharp.fixed} alt={'Creative Learning Academy, FL'} />
}
