import React from 'react'
import { Modal } from 'antd'
import ResizeDetector from 'react-resize-detector'
import { styled } from '@thesisedu/web/dist'
import { VideoFrameWithMute } from './Video'

const Container = styled.div`
  position: relative;
  border-radius: ${(props) => props.theme['@border-radius-large']};
  overflow: hidden;
`

export interface VideoModalProps {
  visible?: boolean
  onVisibleChange: (visible: boolean) => void
  hlsUrl: string
  muteDefault?: boolean
}
export function VideoModal({ visible, onVisibleChange, hlsUrl, muteDefault }: VideoModalProps) {
  return (
    <Modal
      width={900}
      style={{ maxWidth: '98vw' }}
      bodyStyle={{ padding: 0 }}
      onCancel={() => onVisibleChange(false)}
      visible={visible}
      footer={null}
      destroyOnClose
      className={'modal-no-close'}
    >
      <ResizeDetector
        handleWidth
        render={({ width }) => (
          <Container>
            <VideoFrameWithMute
              width={width}
              hlsUrl={hlsUrl}
              muteDefault={muteDefault}
              autoplay
            />
          </Container>
        )}
      />
    </Modal>
  )
}
